import jsPDF from "jspdf"
import "jspdf-autotable"
import { simpleFetch, acknowledgeErrors, fetchCustomer } from "./index"
import moment from "moment"
import { stringifyQuery } from "../helpers/qs"

export const BOOKINGDETAILS_UICHANGE = "BOOKINGDETAILS_UICHANGE"
export const bookingDetailsUIChange = changes => ({ type: BOOKINGDETAILS_UICHANGE, changes })

export const BOOKINGDETAILS_RESETUI = "BOOKINGDETAILS_RESETUI"
export const bookingDetailsResetUI = () => ({ type: BOOKINGDETAILS_RESETUI })

export const NEWBOOKING_GOTOSTEP = "NEWBOOKING_GOTOSTEP"
export const newBookingGoToStep = step => ({ type: NEWBOOKING_GOTOSTEP, step: step })

export const NEWBOOKINGMODAL_DATACHANGE = "NEWBOOKINGMODAL_DATACHANGE"
export const newBookingModalDataChange = changes => ({ type: NEWBOOKINGMODAL_DATACHANGE, changes })

export const PSV_INPUT_CHANGE = "PSV_INPUT_CHANGE"
export const psvInputChange = psv => ({ type: PSV_INPUT_CHANGE, psv })

export const PSV_TOGGLE_SKIP_CODE = "PSV_TOGGLE_SKIP_CODE"
export const psvToggleSkipCode = () => ({ type: PSV_TOGGLE_SKIP_CODE })

export const NEWBOOKINGPAGE_PREFILL = "NEWBOOKINGPAGE_PREFILL"
export const prefillNewBookingPage = changes => ({ type: NEWBOOKINGPAGE_PREFILL, changes })

export const NEWBOOKING_STATE_CHANGE = "NEWBOOKING_STATE_CHANGE"
export const newBookingStateChange = changes => ({ type: NEWBOOKING_STATE_CHANGE, changes })

export const CANCELBOOKINGMODAL_CHANGE = "CANCELBOOKINGMODAL_CHANGE"
export const cancelBookingModalChange = changes => ({ type: CANCELBOOKINGMODAL_CHANGE, changes })

export const EDITBOOKING_INIT = "EDITBOOKING_INIT"
export const editBookingInit = (editedBookingID, editedBookingDate) => ({
	type: EDITBOOKING_INIT,
	editedBookingID,
	editedBookingDate,
})

export const NEWBOOKING_READYFORSUBMIT = "NEWBOOKING_READYFORSUBMIT"
export const newBookingReadyForSubmit = () => ({ type: NEWBOOKING_READYFORSUBMIT })

export const BOOKING_FILTERS_STATE_CHANGE = "BOOKING_FILTERS_STATE_CHANGE"
export const bookingFiltersStateChange = changes => ({
	type: BOOKING_FILTERS_STATE_CHANGE,
	changes,
})

export const RESET_TIMESLOTS = "RESET_TIMESLOTS"
export const resetTimeslots = () => ({ type: RESET_TIMESLOTS })

export const RESET_NEWBOOKING_FORM = "RESET_NEWBOOKING_FORM"
export const resetNewBookingForm = () => ({ type: RESET_NEWBOOKING_FORM })

export const EDIT_CURRENTBOOKING = "EDIT_CURRENTBOOKING"
export const editCurrentBooking = data => ({ type: EDIT_CURRENTBOOKING, data })

export const EDIT_DIRECTBOOKING = "EDIT_DIRECTBOOKING"
export const editDirectBooking = data => ({ type: EDIT_DIRECTBOOKING, data: data })

export const RESET_DIRECTBOOKING = "RESET_DIRECTBOOKING"
export const resetDirectBookingForm = () => ({ type: RESET_DIRECTBOOKING })

export const DIRECTBOOKING_STATE_CHANGE = "DIRECTBOOKING_STATE_CHANGE"
export const directBookingModalStateChange = changes => ({
	type: DIRECTBOOKING_STATE_CHANGE,
	changes,
})

export const DELETEBOOKINGMODAL_INIT = "DELETEBOOKINGMODAL_INIT"
export const deleteBookingModalInit = init => ({ type: DELETEBOOKINGMODAL_INIT, init })

export const CANCELBOOKINGMODAL_INIT = "CANCELBOOKINGMODAL_INIT"
export const cancelBookingModalInit = init => ({ type: CANCELBOOKINGMODAL_INIT, init })

export const DIRECTBOOKING_CREATIONGWARNING = "DIRECTBOOKING_CREATIONGWARNING"
export const directBookingCreationWarning = error => ({
	type: DIRECTBOOKING_CREATIONGWARNING,
	error,
})

export const DIRECT_BOOKING_MODIFICATION_STATE_CHANGE = "DIRECT_BOOKING_MODIFICATION_STATE_CHANGE"
export const directBookingModificationStateChange = changes => ({
	type: DIRECT_BOOKING_MODIFICATION_STATE_CHANGE,
	changes,
})

export const RESET_DIRECT_BOOKING_MODIFICATION_MODAL = "RESET_DIRECT_BOOKING_MODIFICATION_MODAL"
export const resetDirectBookingModificationModal = () => ({
	type: RESET_DIRECT_BOOKING_MODIFICATION_MODAL,
})

const fetchLinkedCustomerContracts = (id, actionType) => async (dispatch, getState) => {
	if (!id) {
		return
	}
	let init = { method: "GET" }
	let url = "/front/affiliates/customer_contracts/" + id + "/linked_customer_contracts"
	return dispatch(simpleFetch(url, init, actionType))
}

export const FETCH_BOOKING_LINKED_CUSTOMERS = "FETCH_BOOKING_LINKED_CUSTOMERS"
export const FETCH_BOOKING_LINKED_CUSTOMERS_REQUEST = "FETCH_BOOKING_LINKED_CUSTOMERS_REQUEST"
export const FETCH_BOOKING_LINKED_CUSTOMERS_SUCCESS = "FETCH_BOOKING_LINKED_CUSTOMERS_SUCCESS"
export const FETCH_BOOKING_LINKED_CUSTOMERS_FAILURE = "FETCH_BOOKING_LINKED_CUSTOMERS_FAILURE"
export const fetchBookingCustomerLinkedCustomerContracts = id => async (dispatch, getState) => {
	dispatch(fetchLinkedCustomerContracts(id, FETCH_BOOKING_LINKED_CUSTOMERS))
}

export const FETCH_BOOKING_CUSTOMER = "FETCH_BOOKING_CUSTOMER"
export const FETCH_BOOKING_CUSTOMER_REQUEST = "FETCH_CUSTOMER_REQUEST"
export const FETCH_BOOKING_CUSTOMER_SUCCESS = "FETCH_BOOKING_CUSTOMER_SUCCESS"
export const FETCH_BOOKING_CUSTOMER_FAILURE = "FETCH_BOOKING_CUSTOMER_FAILURE"
export const fetchBookingCustomerContract = id => async (dispatch, getState) => {
	dispatch(fetchCustomer(id, FETCH_BOOKING_CUSTOMER))
}

export const FETCH_WORKSHOP_ORDER_LINKED_CUSTOMERS = "FETCH_WORKSHOP_ORDER_LINKED_CUSTOMERS"
export const FETCH_WORKSHOP_ORDER_LINKED_CUSTOMERS_REQUEST =
	"FETCH_WORKSHOP_ORDER_LINKED_CUSTOMERS_REQUEST"
export const FETCH_WORKSHOP_ORDER_LINKED_CUSTOMERS_SUCCESS =
	"FETCH_WORKSHOP_ORDER_LINKED_CUSTOMERS_SUCCESS"
export const FETCH_WORKSHOP_ORDER_LINKED_CUSTOMERS_FAILURE =
	"FETCH_WORKSHOP_ORDER_LINKED_CUSTOMERS_FAILURE"
export const fetchWorkshopOrderLinkedCustomerContracts = id => async (dispatch, getState) => {
	dispatch(fetchLinkedCustomerContracts(id, FETCH_WORKSHOP_ORDER_LINKED_CUSTOMERS))
}

export const ADD_EVENT_EXCEPTION = "ADD_EVENT_EXCEPTION"
export const ADD_EVENT_EXCEPTION_REQUEST = "ADD_EVENT_EXCEPTION_REQUEST"
export const ADD_EVENT_EXCEPTION_SUCCESS = "ADD_EVENT_EXCEPTION_SUCCESS"
export const ADD_EVENT_EXCEPTION_FAILURE = "ADD_EVENT_EXCEPTION_FAILURE"

export const NEWBOOKING_REQUEST = "NEWBOOKING_REQUEST"
export const NEWBOOKING_SUCCESS = "NEWBOOKING_SUCCESS"
export const NEWBOOKING_FAILURE = "NEWBOOKING_FAILURE"

export const DELETEBOOKING_REQUEST = "DELETEBOOKING_REQUEST"
export const DELETEBOOKING_SUCCESS = "DELETEBOOKING_SUCCESS"
export const DELETEBOOKING_FAILURE = "DELETEBOOKING_FAILURE"

export const NEWBOOKING_SUBMITNEWRESIDENCE_REQUEST = "NEWBOOKING_SUBMITNEWRESIDENCE_REQUEST"
export const NEWBOOKING_SUBMITNEWRESIDENCE_SUCCESS = "NEWBOOKING_SUBMITNEWRESIDENCE_SUCCESS"
export const NEWBOOKING_SUBMITNEWRESIDENCE_FAILURE = "NEWBOOKING_SUBMITNEWRESIDENCE_FAILURE"

export const NEWDIRECTBOOKING_SUBMITNEWRESIDENCE_REQUEST =
	"NEWDIRECTBOOKING_SUBMITNEWRESIDENCE_REQUEST"
export const NEWDIRECTBOOKING_SUBMITNEWRESIDENCE_SUCCESS =
	"NEWDIRECTBOOKING_SUBMITNEWRESIDENCE_SUCCESS"
export const NEWDIRECTBOOKING_SUBMITNEWRESIDENCE_FAILURE =
	"NEWDIRECTBOOKING_SUBMITNEWRESIDENCE_FAILURE"

export const FETCHBOOKINGS = "FETCHBOOKINGS"
export const FETCHBOOKINGS_REQUEST = "FETCHBOOKINGS_REQUEST"
export const FETCHBOOKINGS_SUCCESS = "FETCHBOOKINGS_SUCCESS"
export const FETCHBOOKINGS_FAILURE = "FETCHBOOKINGS_FAILURE"

export const FETCHBOOKING = "FETCHBOOKING"
export const FETCHBOOKING_REQUEST = "FETCHBOOKING_REQUEST"
export const FETCHBOOKING_SUCCESS = "FETCHBOOKING_SUCCESS"
export const FETCHBOOKING_FAILURE = "FETCHBOOKING_FAILURE"
export const fetchBooking = bookingId => async (dispatch, getState) => {
	let init = { method: "GET" }
	let url = "/front/affiliates/bookings/" + bookingId
	let { actionType, data } = await dispatch(simpleFetch(url, init, FETCHBOOKING))
	if (actionType === FETCHBOOKING_SUCCESS) {
		dispatch(
			fetchBookingCustomerLinkedCustomerContracts(
				data.data.attributes.customer.customer_contract_id
			)
		)
	}
}

export const FETCHEDITEDBOOKING_REQUEST = "FETCHEDITEDBOOKING_REQUEST"
export const FETCHEDITEDBOOKING_SUCCESS = "FETCHEDITEDBOOKING_SUCCESS"
export const FETCHEDITEDBOOKING_FAILURE = "FETCHEDITEDBOOKING_FAILURE"

export const BOOKINGFILTERS = "BOOKINGFILTERS"
export const BOOKINGFILTERS_REQUEST = "BOOKINGFILTERS_REQUEST"
export const BOOKINGFILTERS_SUCCESS = "BOOKINGFILTERS_SUCCESS"
export const BOOKINGFILTERS_FAILURE = "BOOKINGFILTERS_FAILURE"

export const CHANGEBOOKINGSTATUS_REQUEST = "CHANGEBOOKINGSTATUS_REQUEST"
export const CHANGEBOOKINGSTATUS_SUCCESS = "CHANGEBOOKINGSTATUS_SUCCESS"
export const CHANGEBOOKINGSTATUS_FAILURE = "CHANGEBOOKINGSTATUS_FAILURE"

export const CANCELBOOKING_REQUEST = "CANCELBOOKING_REQUEST"
export const CANCELBOOKING_SUCCESS = "CANCELBOOKING_SUCCESS"
export const CANCELBOOKING_FAILURE = "CANCELBOOKING_FAILURE"

export const TIMESLOTS_REQUEST = "TIMESLOTS_REQUEST"
export const TIMESLOTS_SUCCESS = "TIMESLOTS_SUCCESS"
export const TIMESLOTS_FAILURE = "TIMESLOTS_FAILURE"

export const SUBMITBOOKINGMESSAGE_REQUEST = "SUBMITBOOKINGMESSAGE_REQUEST"
export const SUBMITBOOKINGMESSAGE_SUCCESS = "SUBMITBOOKINGMESSAGE_SUCCESS"
export const SUBMITBOOKINGMESSAGE_FAILURE = "SUBMITBOOKINGMESSAGE_FAILURE"

export const PSV_RELATED_BOOKINGS_REQUEST = "PSV_RELATED_BOOKINGS_REQUEST"
export const PSV_RELATED_BOOKINGS_SUCCESS = "PSV_RELATED_BOOKINGS_SUCCESS"
export const PSV_RELATED_BOOKINGS_FAILURE = "PSV_RELATED_BOOKINGS_FAILURE"

export const SUBMIT_PSV_REQUEST = "SUBMIT_PSV_REQUEST"
export const SUBMIT_PSV_SUCCESS = "SUBMIT_PSV_SUCCESS"
export const SUBMIT_PSV_FAILURE = "SUBMIT_PSV_FAILURE"

export const REMOVE_PSV_REQUEST = "REMOVE_PSV_REQUEST"
export const REMOVE_PSV_SUCCESS = "REMOVE_PSV_SUCCESS"
export const REMOVE_PSV_FAILURE = "REMOVE_PSV_FAILURE"

export const REMOVE_SODEXO_PSV = "REMOVE_SODEXO_PSV"
export const REMOVE_SODEXO_PSV_REQUEST = "REMOVE_SODEXO_PSV_REQUEST"
export const REMOVE_SODEXO_PSV_SUCCESS = "REMOVE_SODEXO_PSV_SUCCESS"
export const REMOVE_SODEXO_PSV_FAILURE = "REMOVE_SODEXO_PSV_FAILURE"

export const SUBMIT_BOOKINGNOTEUPDATE_REQUEST = "SUBMIT_BOOKINGNOTEUPDATE_REQUEST"
export const SUBMIT_BOOKINGNOTEUPDATE_SUCCESS = "SUBMIT_BOOKINGNOTEUPDATE_SUCCESS"
export const SUBMIT_BOOKINGNOTEUPDATE_FAILURE = "SUBMIT_BOOKINGNOTEUPDATE_FAILURE"

export const UPDATE_BOOKING_SODEXO_STATUS_REQUEST = "UPDATE_BOOKING_SODEXO_STATUS_REQUEST"
export const UPDATE_BOOKING_SODEXO_STATUS_SUCCESS = "UPDATE_BOOKING_SODEXO_STATUS_SUCCESS"
export const UPDATE_BOOKING_SODEXO_STATUS_FAILURE = "UPDATE_BOOKING_SODEXO_STATUS_FAILURE"

export const SEND_PSV_REMINDER_REQUEST = "SEND_PSV_REMINDER_REQUEST"
export const SEND_PSV_REMINDER_SUCCESS = "SEND_PSV_REMINDER_SUCCESS"
export const SEND_PSV_REMINDER_FAILURE = "SEND_PSV_REMINDER_FAILURE"

export const SEND_ESV_REMINDER_REQUEST = "SEND_ESV_REMINDER_REQUEST"
export const SEND_ESV_REMINDER_SUCCESS = "SEND_ESV_REMINDER_SUCCESS"
export const SEND_ESV_REMINDER_FAILURE = "SEND_ESV_REMINDER_FAILURE"

export const fetchBookings = (customQuery = {}, meta = {}) => (dispatch, getState) => {
	let {
		currentURLQuery: { realQuery },
	} = getState()
	let init = { method: "GET" }
	let finalQuery = Object.keys(realQuery).reduce((acc, key) => {
		let value = realQuery[key]
		if (!value) return acc
		acc[key] = value
		if (moment.isMoment(value)) {
			acc[key] = value.format("YYYY-MM-DD")
		}
		return acc
	}, {})
	let url = "/front/affiliates/bookings" + stringifyQuery({ ...finalQuery, ...customQuery })
	dispatch(simpleFetch(url, init, FETCHBOOKINGS, meta))
}

export const fetchBookingFilters = () => (dispatch, getState) => {
	let {
		redComponents: { bookingFiltersComponent },
	} = getState()
	if (bookingFiltersComponent.fetched) return
	let init = { method: "GET" }
	let url = "/front/affiliates/booking_filter"
	dispatch(simpleFetch(url, init, BOOKINGFILTERS))
}

export const FETCHEDITEDBOOKING = "FETCHEDITEDBOOKING"
export const fetchEditedBooking = (editedBookingID, editedBookingDate) => (dispatch, getState) => {
	let init = { method: "GET" }
	let url = "/front/affiliates/bookings/" + editedBookingID
	if (editedBookingDate) {
		url += "?recurrence_date=" + editedBookingDate
	}
	dispatch(simpleFetch(url, init, "FETCHEDITEDBOOKING"))
}

export const DIRECT_BOOKING_MODIFICATION_REQUEST = "DIRECT_BOOKING_MODIFICATION_REQUEST"
export const DIRECT_BOOKING_MODIFICATION_SUCCESS = "DIRECT_BOOKING_MODIFICATION_SUCCESS"
export const DIRECT_BOOKING_MODIFICATION_FAILURE = "DIRECT_BOOKING_MODIFICATION_FAILURE"
export const submitDirectBookingModification = () => async (dispatch, getState) => {
	let {
		redComponents: {
			impactedBookingsModal: { forceUnlink },
			directBookingModificationModal: {
				bookingID,
				startTime,
				endTime,
				dayPeriod,
				voucherType,
				reach,
				ssCode,
				newCustomerContractId,
				otherCustomerContractId,
				postSubmitAction,
				customerContractId,
				sentToSodexo,
				residenceIndex,
				chosenCustomer,
			},
		},
	} = getState()
	if (reach === "" || (reach === "all_occurrences_from_date" && !dayPeriod)) {
		dispatch(
			directBookingModificationStateChange({
				showRequired: { reach: true, voucherType: true, dayPeriod: true },
			})
		)
		return
	}

	let data = {
		booking_id: bookingID,
		update_type: reach,
		start_time: startTime.format(),
		end_time: endTime.format(),
		day_period: Number(dayPeriod),
		voucher_type: voucherType,
		ss_code: ssCode,
		customer_contract_id:
			newCustomerContractId || otherCustomerContractId || customerContractId,
		force_unlink: forceUnlink,
		residence_id: chosenCustomer.attributes.residences.data[residenceIndex || "0"].id,
	}
	if (sentToSodexo) {
		data.force = true
	}
	let init = { method: "PUT", body: JSON.stringify(data) }
	let url = `/front/affiliates/bookings/${bookingID}/direct_update`
	let { actionType } = await dispatch(simpleFetch(url, init, "DIRECT_BOOKING_MODIFICATION"))
	if (actionType === DIRECT_BOOKING_MODIFICATION_SUCCESS) {
		dispatch(postSubmitAction)
	}
}

export const FETCH_ALL_BOOKINGS = "FETCH_ALL_BOOKINGS"
export const FETCH_ALL_BOOKINGS_REQUEST = "FETCH_ALL_BOOKINGS_REQUEST"
export const FETCH_ALL_BOOKINGS_SUCCESS = "FETCH_ALL_BOOKINGS_SUCCESS"
export const FETCH_ALL_BOOKINGS_FAILURE = "FETCH_ALL_BOOKINGS_FAILURE"
export const createBookingsListPDF = translate => async (dispatch, getState) => {
	let {
		currentURLQuery: { realQuery },
	} = getState()
	let url = "/front/affiliates/bookings/list" + stringifyQuery(realQuery)
	let init = { method: "GET" }
	let { actionType, data } = await dispatch(simpleFetch(url, init, FETCH_ALL_BOOKINGS))
	const checkMarkContent = {
		content: { none: "", blue: "V", black: "V" },
		color: { none: [0, 0, 0], blue: [125, 205, 219], black: [0, 0, 0] },
	}
	if (actionType === FETCH_ALL_BOOKINGS_SUCCESS) {
		let customersListForPDF = {
			showHead: "firstPage",
			margin: { top: 5, left: 5, bottom: 5, right: 5 },
			styles: {
				fontSize: 9,
				cellPadding: 1,
				halign: "left",
				valign: "middle",
				cellWidth: "wrap",
			},
			columns: [
				{ header: translate("date"), dataKey: "scheduled_date" },
				{ header: translate("worker"), dataKey: "worker_name" },
				{ header: translate("customer"), dataKey: "customer_name" },
				{ header: translate("start_time"), dataKey: "start_time" },
				{ header: translate("end_time"), dataKey: "end_time" },
				{ header: translate("voucher_type_abbrev"), dataKey: "voucher_type" },
				{ header: translate("encoded_vouchers_abbrev"), dataKey: "encoded_vouchers" },
				{ header: translate("paid_vouchers_abbrev"), dataKey: "paid_vouchers" },
				{ header: translate("remaining_vouchers_abbrev"), dataKey: "remaining_vouchers" },
				{ header: translate("status"), dataKey: "status" },
			],
			body: data.data.map(entry => ({
				scheduled_date: entry.attributes.scheduled_date,
				worker_name: entry.attributes.worker_name,
				customer_name: entry.attributes.customer_name,
				start_time: entry.attributes.start_time,
				end_time: entry.attributes.end_time,
				voucher_type: entry.attributes.voucher_type,
				encoded_vouchers: entry.attributes.encoded_vouchers,
				paid_vouchers: entry.attributes.paid_vouchers,
				remaining_vouchers: entry.attributes.remaining_vouchers,
				status: {
					content:
						entry.attributes.status.label +
						" " +
						checkMarkContent.content[entry.attributes.status.color],
					styles: { textColor: checkMarkContent.color[entry.attributes.status.color] },
				},
			})),
		}
		let doc = new jsPDF("landscape")
		doc.text(`${moment().format("DD/MM/YY")} ${translate("bookings_lists_pdf_header")}`, 5, 10)
		doc.autoTable(customersListForPDF)
		doc.save(`${translate("bookings_list")}.pdf`)
	}
}

export const fetchTimeSlotsForCurrentMonth = startDate => (dispatch, getState) => {
	let {
		redComponents: {
			newBookingComponent: { editionMode },
		},
		redData: {
			currentEditedBooking,
			currentNewBookingFormData: {
				reach,
				duration,
				selectedDate,
				language,
				specificWorker,
				recurrence,
				chosenCustomer,
				residenceIndex,
			},
		},
	} = getState()
	dispatch(resetTimeslots())
	dispatch(
		newBookingStateChange({
			showFeaturedWorker: false,
			showTimeSlots: false,
			showTimeslotsScrollHint: false,
		})
	)
	let excludeCurrentBooking = false,
		currentBookingId = -1
	if (editionMode) {
		excludeCurrentBooking = true
		currentBookingId = currentEditedBooking.id
		if (reach === "custom") {
			recurrence = "0"
		}
	}
	let residence = chosenCustomer.residences[residenceIndex]
	let query =
		`?duration_in_hours=${duration.toString().replace(",", ".")}` +
		`&zip_code=${residence.attributes.zip_code}&language_id=${language}` +
		`&recurrence_type=${recurrence}&worker_sodexo_reference=${specificWorker.sodexo}` +
		`&customer_contract_id=${chosenCustomer.id}` +
		`&residence_id=${residence.id}` +
		`${excludeCurrentBooking ? `&ignore_booking_in_timeslot_search=${currentBookingId}` : ""}`

	startDate = moment().isAfter(startDate) ? moment() : startDate
	let datesToCheck = [],
		stopDate = moment(startDate).endOf("month")
	while (startDate.isBefore(stopDate)) {
		datesToCheck.push(moment(startDate))
		startDate.add(1, "week")
	}
	let warningsToShow = []
	datesToCheck.forEach(async entry => {
		let finalQuery = query + "&delivery_date=" + entry.format("YYYY-MM-DD")
		let { actionType, data, error } = await dispatch(
			simpleFetch(
				"/front/affiliates/search_time_slots" + finalQuery,
				{ method: "GET" },
				"TIMESLOTS"
			)
		)
		if (
			actionType === TIMESLOTS_SUCCESS &&
			editionMode &&
			selectedDate &&
			data.delivery_dates.includes(selectedDate.format("YYYY-MM-DD"))
		) {
			// If the current booking form is for a booking modification
			// AND the previously selected date has been fetched
			// then show the selection
			dispatch(newBookingStateChange({ showTimeSlots: true, showTimeslotsScrollHint: true }))
		} else if (actionType === TIMESLOTS_FAILURE && error.status === 422) {
			dispatch(acknowledgeErrors())
			warningsToShow.push({
				weekStart: moment(entry).startOf("isoweek"),
				error: error.json.errors,
			})
		}
	})

	dispatch(newBookingStateChange({ serverWarnings: warningsToShow }))
}

export const handleNewBookingDisplayedMonthChange = month => (dispatch, getState) => {
	dispatch(newBookingModalDataChange({ startTime: "", selectedDate: "", selectedWorker: {} }))
	dispatch(fetchTimeSlotsForCurrentMonth(month.startOf("month")))
}

export const newBookingValidateStepOne = () => (dispatch, getState) => {
	let {
		redData: { currentNewBookingFormData, currentEditedBooking },
		redComponents: { newBookingComponent },
	} = getState()
	let toCheck = ["duration", "recurrence", "residenceIndex"],
		valid = true
	if (newBookingComponent.editionMode && currentEditedBooking.recurrence) {
		toCheck.push("reach")
	}
	let newShowRequired = toCheck.reduce((acc, entry) => {
		if (currentNewBookingFormData[entry] === "") {
			valid = false
			acc[entry] = true
		}
		return acc
	}, {})
	if (!currentNewBookingFormData.chosenCustomer.name) {
		valid = false
		newShowRequired.chosenCustomer = true
	}
	if (Object.keys(newShowRequired).length > 0) {
		dispatch(newBookingModalDataChange({ showRequired: newShowRequired }))
	}
	if (valid) {
		dispatch(fetchTimeSlotsForCurrentMonth(moment()))
		dispatch(newBookingGoToStep(2))
		return
	}
}

export const submitNewBooking = () => (dispatch, getState) => {
	let {
		redData: { currentNewBookingFormData, currentEditedBooking },
		redComponents: { newBookingComponent },
	} = getState()
	let startTime = moment(currentNewBookingFormData.startTime, "HH:mm").set({
		year: currentNewBookingFormData.selectedDate.year(),
		month: currentNewBookingFormData.selectedDate.month(),
		date: currentNewBookingFormData.selectedDate.date(),
	})
	let endTime = moment(startTime).add(Number(currentNewBookingFormData.duration), "hours")
	let residence =
		currentNewBookingFormData.chosenCustomer.residences[
			currentNewBookingFormData.residenceIndex
		]
	let data = {
		customer_contract_id: currentNewBookingFormData.chosenCustomer.id,
		residence_id: residence.id,
		delivery_date: currentNewBookingFormData.selectedDate.format(),
		start_time: startTime.format(),
		end_time: endTime.format(),
		end_date:
			currentNewBookingFormData.selectedEndDate &&
			currentNewBookingFormData.selectedEndDate.format("YYYY-MM-DD"),
		worker_contract_id: currentNewBookingFormData.selectedWorker.contractId,
		recurrence: currentNewBookingFormData.recurrence === "0" ? false : true,
		recurrence_delay: Number(currentNewBookingFormData.recurrence),
		duration_in_hours: currentNewBookingFormData.duration,
		language_reference: currentNewBookingFormData.language,
		zip_code: residence.attributes.zip_code,
	}
	let init = { method: "POST", body: JSON.stringify(data) }
	let url =
		"/front/affiliates/customer_contracts/" +
		currentNewBookingFormData.chosenCustomer.id +
		"/bookings"
	if (newBookingComponent.editionMode) {
		data.occurrence_date = moment(currentEditedBooking.delivery_date, "YYYY-MM-DD").format()

		if (currentEditedBooking.recurrence) {
			data.modifications_reach = currentNewBookingFormData.reach
		}
		if (currentEditedBooking.sodexo_status) {
			data.force = true
		}
		init = { method: "PUT", body: JSON.stringify(data) }
		url =
			"/front/affiliates/bookings/" +
			(currentEditedBooking.dummy
				? currentEditedBooking.parent_booking_id
				: currentEditedBooking.id)
	}
	dispatch(simpleFetch(url, init, "NEWBOOKING"))
}

export const processNewBookingStepChange = newStep => (dispatch, getState) => {
	if (newStep === 2) {
		dispatch(newBookingValidateStepOne())
	} else {
		dispatch(newBookingModalDataChange({ startTime: "", selectedWorker: {}, selectedDate: "" }))
		dispatch(
			newBookingStateChange({
				showTimeSlots: false,
				showFeaturedWorker: false,
			})
		)
		dispatch(newBookingGoToStep(newStep))
	}
}

export const submitCancelBooking = (cancelDate = "") => (dispatch, getState) => {
	let {
		redComponents: {
			cancelBookingModal: {
				recurrentBooking,
				createPendingBookingWishes,
				deliveryDate,
				sentToSodexo,
				bookingID,
				requestMeta,
				reach,
			},
			impactedBookingsModal: { forceUnlink },
		},
	} = getState()
	if (recurrentBooking && reach === "") {
		dispatch(cancelBookingModalChange({ showRequired: { reach: true } }))
		return
	}
	let body = {
		create_booking_wishes: createPendingBookingWishes,
		force_unlink: forceUnlink,
	}
	if (recurrentBooking) {
		body = {
			...body,
			unavailability_start_date: deliveryDate,
			cancel_type: reach,
		}
		if (reach === "custom") {
			// For now the custom type only cancels one booking (the current one) of a recurrent booking
			body["next_delivery_date"] = moment(body.unavailability_start_date)
				.add(1, "days")
				.format()
		}
	}
	if (sentToSodexo) {
		body.force = true
	}
	if (cancelDate !== "") {
		body["scheduled_date"] = cancelDate.format("DD-MM-YYYY")
	}
	let init = { method: "DELETE", body: JSON.stringify(body) }
	let url = "/front/affiliates/bookings/" + bookingID + "/cancel"
	dispatch(simpleFetch(url, init, "CANCELBOOKING", requestMeta))
}
